<template>
  <div class="setting_form p-4 clear">
    <div class="white_box">
      <!--Body-->
      <div class="body">
        <div class="body-title mt-1">
          <h6>Agency Domain Settings</h6>
          <p class="mt-2">By adding your custom domain, you can personalize the domain name for example <strong>(app.yourbrand.com)</strong> to access the Replug platform instead of using <strong>(app.replug.io).</strong> To achieve this, go to the DNS settings of your domain registrar and create a <strong>CNAME</strong> record with the <strong>{{ getWhitelabelDomainENV() }}</strong> value.</p>
        </div>
        <div class="body-section mt-2">
          <hr class="mt-4">
          <p v-if="!getSiteDetails.isWhiteLabel" class="mt-2">First time setting up a custom domain? <a target="_blank" href="https://docs.replug.io/category/166-custom-domains">Check out
            this guide.</a></p>

          <div v-if="!getWhiteLabel.whiteLabel_domain_connected" class="alert w-75 p-2 mt-3" role="alert">
            <i class="fal mr-2 fa-lightbulb"></i> <strong>Important: </strong> make sure you point your domain DNS
            settings to <strong>whitelabel.replug.io</strong>
          </div>

          <div class="mt-3 input_field">
            <div class="w-50">
              <div
                :class="{'input-field-error' : getWhiteLabelValidations.white_label_domain.url || getWhiteLabelValidations.white_label_domain.urlLength || getWhiteLabelValidations.white_label_domain.urlValid}"
                class=" input_icon_text d-flex align-items-center profile__box">
                <div class="content">
                  <div class="url">Enter the domain name</div>
                  <div class="name">
                    <div class="validation_input">
                      <input :disabled="isEditMode && getFieldEditStatus()" @blur="white_label_domain = focusoutConcatenation(white_label_domain)" v-model="white_label_domain" placeholder="eg. whitelabel.replug.io"
                             type="text" maxlength="100">
                    </div>
                  </div>
                </div>
              </div>
              <span v-if="getWhiteLabelValidations.white_label_domain.url" class="input-error">{{
                  whiteLabelMessage.url
                }}</span>
              <span v-else-if="getWhiteLabelValidations.white_label_domain.urlValid"
                    class="input-error">{{ whiteLabelMessage.urlValid }}</span>
              <span v-else-if="getWhiteLabelValidations.white_label_domain.urlLength"
                    class="input-error">{{ whiteLabelMessage.urlLength }}</span>
            </div>
          </div>

          <div class="check_domain_section mt-4 flex align-items-center">
            <strong>{{ getWhiteLabel.white_label_domain }}</strong>
            <div v-if="!isEditMode" class="d-flex align-items-center">
              <span class="campaign_status ml-2 mt-1"></span>
              <p class="ml-2 flex align-items-center">Pending verification.
                <a class="flex ml-1" v-if="!getWhiteLabelLoaders.domain" @click="verifyDomain()">Click here to verify</a>
                <clip-loader v-else class="icon ml-2" style="right: 30px;" :size="'12px'" :color="'#3988fe'"></clip-loader>
              </p>
            </div>
            <div v-else-if="isEditMode && getWhiteLabel.whiteLabel_domain_connected" class="domain_connected d-inline-flex">
              <p class="ml-2"><i class="fal font-weight-bold fa-check"></i> Connected.</p>
            </div>
            <i @click="edit()" class="fal ml-2 fa-pencil"></i>
            <i @click="clear('clear')"class="fal ml-2 fa-trash"></i>
          </div>
        </div>
        <hr>

        <!-- Whitelabel branded links settings -->
        <div class="body">
          <div class="body-title mt-1">
            <h6>Branded Links Settings</h6>
            <p class="mt-2">By adding your branded link domain, you can personalize the domain for example <strong>(link.yourbrand.com)</strong> used for shortening the URL when no custom domain is connected with the campaign. Just like <strong>replug.link</strong> is used for shortening the URLs when no custom domain is connected with the campaign.</p>
          </div>
          <div class="body-section mt-2">
            <div class="w-50 mt-3 mb-5 input_icon_text d-flex align-items-center profile__box">
              <div class="content">
                <div class="url">Select Branded Custom Domain
                  <div v-if="getSiteDetails && getSiteDetails.custom_domain"
                       v-tooltip="domainStatusMessage(getSiteDetails.custom_domain.connectivity)"
                       class="campaign_status"
                       :class="{'active_campaign':
                     getSiteDetails.custom_domain.connectivity, 'disable_campaign': !getSiteDetails.custom_domain.connectivity}"></div>

                </div>
                <div class="name">
                  <div class="validation_input">
                    <b-dropdown right
                                class="  mt-2 dropdown-menu-right default_style_dropdown "
                                :no-caret="true"
                                ref="bio_domains_dropdown_menu">
                      <div :class="{'input-field-error' : getWhiteLabelValidations.custom_domain_id}"
                           class="dropdown_header with-shadow d-flex align-items-center rounded_shape  "
                           slot="button-content">
                        <template v-if="!select_domain">
                          <span class="text">Select custom domain</span>
                        </template>
                        <template v-else>
                          <span class="text">{{ select_domain }}</span>
                        </template>
                        <span class="arrow_icon ml-auto">
                                                    <i class=" fal fa-angle-down ml-3"></i>
                                                 </span>

                      </div>
                      <ul class="inner ">

                        <template v-if="getDomains.items.length > 0"
                                  v-for="domain in getDomains.items">

                          <li @click.prevent="selectDomain(domain)" v-if="domain.connectivity"
                              class="list_item_li">

                            <div class="d-flex align-items-center profile__box">
                              <div class="content">
                                <div class="name">
                                  {{ trimUrl(domain.url) }}
                                </div>

                              </div>
                            </div>

                          </li>

                        </template>
                        <li class="no_text_li">
                          <template>
                            <span v-if="getDomains.items.length === 0">You have not connected any custom domain. <br></span>
                            <hr v-if="getDomains.items.length !== 0">
                            <router-link :to="{name: 'custom_domains'}">Add new custom domain.</router-link>
                          </template>
                        </li>

                      </ul>
                    </b-dropdown>
                    <span v-if="getWhiteLabelValidations.custom_domain_id" class="input-error">Please select your branded domain</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END -->
        <hr>
        <button @click="clear()" type="button" class="mr-2 btn---cta light-blue btn-round  btn-bold"><span>Discard changes</span>
        </button>
        <button @click="applyWhiteLabelChanges()" class="btn---cta  d-inline-flex btn-blue with-shadow btn-round btn-bold">
          <!----><span>Apply changes</span>
          <clip-loader v-if="getWhiteLabelLoaders.store" class="icon ml-2" style="right: 30px;" :size="'12px'" :color="'white'"></clip-loader></button>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {whiteLabelMessage} from "@/common/attributes";

export default {
  name: 'WhitelabelDomainSettings',
  data () {
    return {
      isEditMode: false,
      whiteLabelMessage: whiteLabelMessage,
      select_domain: '',
      white_label_domain: ''
    }
  },
  async created () {

    // if whitelabel already fetched
    if(this.getWhiteLabel && this.getWhiteLabel.agency_name) {
      this.isEditMode = this.getWhiteLabel._id ? true : false
      await this.getDomainName(this.getWhiteLabel.custom_domain_id)
      this.white_label_domain = this.getWhiteLabel.white_label_domain
      return
    }

    await this.fetchWhiteLabel()
    setTimeout(async () => {
      this.isEditMode = this.getWhiteLabel._id ? true : false
      await this.getDomainName(this.getWhiteLabel.custom_domain_id)
      this.white_label_domain = this.getWhiteLabel.white_label_domain
    }, 500)
  },
  computed: {
    ...mapGetters(['getWhiteLabel', 'getWhiteLabelValidations', 'getWhiteLabelLoaders', 'getDomains'])
  },
  methods: {
    ...mapActions(['fetchWhiteLabel', 'verifyDomainRecord', 'deleteWhitelabelDomainSSL']),
    /**
     * getting whitelabel domain from env
     * @returns {string}
     */
    getWhitelabelDomainENV () {
      return process.env.VUE_APP_WHITELABEL_CUSTOM_DOMAIN_CNAME
    },
    /**
     * getting the domain messaged on the basis of status
     * @param status
     * @returns {string}
     */
    domainStatusMessage (status) {
      return status ? 'Your domain is verified and connected.' : 'Your domain is not verified.'
    },
    selectDomain (domain) {
      this.select_domain = this.trimUrl(domain.url)
      this.getWhiteLabel.custom_domain_id = domain._id
      this.getWhiteLabelValidations.custom_domain_id = !this.getWhiteLabel.custom_domain_id
      this.$refs.bio_domains_dropdown_menu.visible = false
    },
    edit() {
      this.isEditMode = false
    },
    async verifyDomain() {

      if(this.getWhiteLabel.white_label_domain === this.white_label_domain) {
        this.isEditMode = this.getWhiteLabel.whiteLabel_domain_connected
        return
      }

      this.getWhiteLabel.white_label_domain = this.white_label_domain
      await this.deleteWhitelabelDomainSSL(this.getWhiteLabel.user_id)
      await this.verifyDomainRecord()
      this.isEditMode = this.getWhiteLabel.whiteLabel_domain_connected
    },
    clear(type = '') {
      if(type === 'clear') {
        this.isEditMode = false
        this.white_label_domain = ''
        return
      }

      if(!this.isEditMode) {
        this.white_label_domain = this.getWhiteLabel.white_label_domain
        this.isEditMode = this.getWhiteLabel.whiteLabel_domain_connected
      }
    },
    async getDomainName (domainId) {
      const domain = await this.getDomains.items.find(item => item._id === domainId)
      this.select_domain = this.trimUrl(domain.url)

    },
    /**
     * check field edit status
     * @returns {this is string[]}
     */
    getFieldEditStatus() {
      return Object.keys(this.getWhiteLabelValidations.white_label_domain).every(k => this.getWhiteLabelValidations.white_label_domain[k] === false)
    }
  },
  watch: {
    'getWhiteLabel.white_label_domain' (value) {
      this.whiteLabelCommonUrlValidation('white_label_domain')
    }
  }
}
</script>
<style scoped lang="less">
.setting_form {
  border-left: 1px solid #d0d6e5;
  min-height: 132px;

  .white_box {
    .box_inner {
      .body {
        .body-title {
          h6 {
            font-weight: bold;
            font-size: small;
          }

          p {
            font-size: small;
          }
        }

        .body-section {
          .alert {
            background: rgba(57, 136, 254, 0.1);
          }
        }

        a {
          color: #1d8bfa;

          &:hover {
            text-decoration: underline;
          }
        }

        .check_domain_section {
          .campaign_status {
            background: rgb(255, 193, 7);
            box-shadow: rgb(240 205 8 / 30%) 0px 5px 7px 0px;
            height: 12px;
            width: 12px;
            border-radius: 100%;
            display: inline-block;
            margin: 0px auto;
          }

          p {
            color: #856404;

            a {
              cursor: pointer;
              color: #856404;
              text-decoration: underline;
            }

          }

          .domain_connected {
            p {
              color: #00ad6c;
            }
          }

          .fa-pencil, .fa-trash {
            border: 1px solid lightgray;
            border-radius: 5px;
            color: lightgray;
            cursor: pointer;
          }

          .fa-pencil {
            padding: 6px;
            color: #3988FE;
            border-color: #3988FE;
          }

          .fa-trash {
            padding: 6px 8px;
            color: #f2405d;
            border-color: #f2405d;
          }

          .fa-pencil:hover {
            color: white;
            background: #3988FE;
          }

          .fa-trash:hover {
            color: white;
            background: #f2405d;
          }

        }
      }
    }
  }
}
</style>
